
import Vue from 'vue'
import ModalTemplate from '../modal-template/template.vue'

export default {
  el: '#payment-form',
  components: {
    [ModalTemplate.name]: ModalTemplate,
  },
  data: {
    achAccountName: null,
    achAccountType: null,
    achToken: null,
    bridgeTerms: false,
    address1: null,
    city: null,
    state: null,
    zip: null,
    poNumber: null,
    Notes:null,
    ccToken: null,
    ccExp: null,
    cvv: null,
    paymentType: null,
    appliedCredit: 0,
    creditAmount: null,
    billing_address: true
  },
  created: function(){
    var organization_address = $('#organization-address').data('address');
    if(organization_address){
      this.address1 = organization_address.street1;
      this.city = organization_address.city;
      this.state = organization_address.state;
      this.zip = organization_address.zip;
    }
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('message', this.handleTokenizingResponse);
    });
    $("#billing_div #credits").click();
    this.selectCredits();
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleTokenizingResponse);
  },
  computed: {
    disableForm() {
      const termsFieldExists = document.querySelector('input#payment_terms_and_conditions');
      const invoice_indicator = document.getElementById('invoice_value').value;
      const baseDataInvalid = (!this.bridgeTerms && termsFieldExists) ||
                              !this.address1 ||
                              !this.city ||
                              !this.state ||
                              !(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(this.zip);
      const achDataInvalid = !this.achAccountName ||
                             !this.achToken ||
                             !this.achAccountType;

      const ccDataInvalid = !this.ccToken ||
                            !this.ccExp ||
                            !this.cvv;
                      

      if (this.paymentType == 'pay_by_invoice') {
        if(!this.bridgeTerms && termsFieldExists){
          return true;
        }else if(invoice_indicator == 0 || invoice_indicator == 1 ){
          $('#error_div').hide();
          return false;
        }else{
          $('#error_div').show();
          return true;
        }
      }else if(this.paymentType == 'pay_by_deposit'){
        return baseDataInvalid;
      }else if (this.paymentType == null && this.creditsCoverTotalCost() && ((termsFieldExists && this.bridgeTerms) || !termsFieldExists) ) {
        return false;
      }else if (this.paymentType == 'credit') {
        return baseDataInvalid || ccDataInvalid;
      }else if(this.paymentType == 'ach'){
        return baseDataInvalid || achDataInvalid;
      }else{
        return true;
      }
    },
    payingWithCredit() {
      return this.paymentType == 'credit';
    },
    payingWithACH() {
      return this.paymentType == 'ach';
    }
  },
  methods: {
    unableToAddCredit: function () {
      //var totalCost = $('#order-total').data('amount');
      var totalCost = document.getElementById("total_cost_show").value;
      var credit_disabled = this.availableCredits() - this.creditAmount < 0 ||
                   this.creditAmount + this.totalPaid() + this.appliedCredit > totalCost ||
                   this.creditAmount > totalCost ||
                   !this.creditAmount;

      if (credit_disabled) return true;

      return this.creditAmount == null || Number(this.creditAmount) < 0 || isNaN(this.creditAmount);
    },
    addCredit: function () {
      var totalCost_without_extra_fee = document.getElementById("total_value").value;
      if (this.unableToAddCredit()) return true;
      this.appliedCredit += this.creditAmount;
      this.creditAmount = null;
      if (this.appliedCredit == totalCost_without_extra_fee){
        this.setClassesFromElement("credit_card", "btn disabled");
        this.setClassesFromElement("e_check", "btn disabled");
        this.setClassesFromElement("invoice", "btn disabled");
        this.setClassesFromElement("direct_deposit", "btn col-md-2 disabled");
      }
    },
    setClassesFromElement: function(idElement, classes){
      let eCheckButton = document.getElementById(idElement);
      if(eCheckButton){
        eCheckButton.className = classes;
      }
    },
    deleteCredit: function(){
      this.appliedCredit = 0;
      this.setClassesFromElement("credit_card", "btn");
      this.setClassesFromElement("e_check", "btn");
      this.setClassesFromElement("invoice", "btn");
      this.setClassesFromElement("direct_deposit", "btn col-md-2");
    },
    availableCredits() {
      var currentCredits = $("#total-credits").data('creditsAvailable');
      return (currentCredits - this.appliedCredit);
    },

    creditsCoverTotalCost() {
      //var totalCost = $('#order-total').data('amount');
      var totalCost = document.getElementById("total_cost_show").value;
      return (this.appliedCredit >= totalCost);
    },
    setNewPaymentMethod(newMethod) {
      const hiddenField = document.getElementById('payment_payment_type');
      hiddenField.value = newMethod;
      this.paymentType = newMethod;
    },
    selectCreditCard() {
      var totalCost_without_extra_fee = document.getElementById("total_value").value;
      var extra_fee = document.getElementById("extra_fee").value;
      $('#extra_charge_div').show();
      $('#total_with_extra_charge').show();
      $('#total_without_extra_charge').hide();
      document.getElementById("is_invoice").value = 0;
      document.getElementById("payment_method").innerHTML = 'Payment Method: <b>CREDIT CARD</b>'
      document.getElementById("is_direct_deposit").value = 0;
      document.getElementById("is_direct_deposit").checked = false;
      this.setNewPaymentMethod('credit');    
      if(Number(extra_fee)!=0){
        document.getElementById("total_cost").value = (Number(totalCost_without_extra_fee) + (Number(totalCost_without_extra_fee - this.appliedCredit)*Number(extra_fee))).toFixed(2);
        document.getElementById('total_cost_show').value = document.getElementById("total_cost").value;
      }
      this.billing_address = true
      if (this.check_submit_button_enable() == false ){
        document.getElementById("submit_order").disabled = false;
      }else{
        document.getElementById("submit_order").disabled = true;
      }
    },
    calculateTotalWithCreditCardFee(){
      return this.calculateTotalWithoutCreditCardFee() + this.calculateCreditCardFee();
    },
    calculateTotalWithoutCreditCardFee(){
      let totalCost_without_extra_fee = Number(document.getElementById("total_value").value);
      return totalCost_without_extra_fee - this.totalPaid() - this.appliedCredit;
    },
    totalPaid(){
      let creditsAppliedBefore = Number(document.getElementById("credits_applied_before").value);
      let cashPayment = Number(document.getElementById("paid_amount").value);
      return creditsAppliedBefore + cashPayment;
    },
    calculateCreditCardFee(){
      let extra_fee = Number(document.getElementById("extra_fee").value);
      return this.calculateTotalWithoutCreditCardFee() * extra_fee;
    },
    creditCardFeeFormated(){
      return "$"+this.calculateCreditCardFee().toFixed(2);
    },
    selectACHAccount() {
      $('#extra_charge_div').hide();
      $('#total_with_extra_charge').hide();
      $('#total_without_extra_charge').show();
      document.getElementById('total_cost_show').value = document.getElementById("total_value").value;
      document.getElementById("is_invoice").value = 0;
      document.getElementById("is_invoice").checked = false;
      document.getElementById("is_direct_deposit").value = 0;
      document.getElementById("is_direct_deposit").checked = false;
      this.setNewPaymentMethod('ach');
      document.getElementById("payment_method").innerHTML = 'Payment Method: <b>ECHECK/ACH</b>'
      this.billing_address = true
      if (this.check_submit_button_enable() == false ){
        document.getElementById("submit_order").disabled = false;
      }else{
        document.getElementById("submit_order").disabled = true;
      }
    },
    selectDirectDeposit(){
      $('#extra_charge_div').hide();
      $('#total_with_extra_charge').hide();
      $('#total_without_extra_charge').show();
      document.getElementById('total_cost_show').value = document.getElementById("total_value").value;
      document.getElementById("is_direct_deposit").value = 1;
      document.getElementById("is_direct_deposit").checked = true;
      this.paymentType = "pay_by_deposit";
      this.billing_address = true
      document.getElementById("is_invoice").value = 0;
      document.getElementById("is_invoice").checked = false;
      document.getElementById("payment_method").innerHTML = 'Payment Method: <b>Direct Deposit</b>'
    },
    selectCredits() {
      $('#extra_charge_div').hide();
      $('#total_with_extra_charge').hide();
      $('#total_without_extra_charge').show();
      var totalCost = document.getElementById("total_cost_show").value;
      document.getElementById('total_cost_show').value = document.getElementById("total_value").value;
      document.getElementById("is_invoice").value = 0;
      document.getElementById("is_invoice").checked = false;
      this.billing_address = false;
      document.getElementById("is_direct_deposit").value = 0;
      document.getElementById("is_direct_deposit").checked = false;
      this.paymentType = null;
      document.getElementById("payment_method").innerHTML = 'Payment Method: <b>CREDITS</b>'
      if (this.check_submit_button_enable() == false ){
        document.getElementById("submit_order").disabled = false;
      }else{
        document.getElementById("submit_order").disabled = true;
      }
    },
    payingWithInvoice(){
      this.billing_address = false
      document.getElementById("is_invoice").value = 1;
      document.getElementById("is_invoice").checked = true;
      var totalCost = document.getElementById("total_cost_show").value;
      this.paymentType = "pay_by_invoice";
      document.getElementById("payment_method").innerHTML = 'Payment Method: <b>PAY BY INVOICE</b>'
      $('#extra_charge_div').hide();
      $('#total_with_extra_charge').hide();
      $('#total_without_extra_charge').show();
      document.getElementById('total_cost_show').value = document.getElementById("total_value").value;
    },
    check_submit_button_enable(){
      const termsFieldExists = document.querySelector('input#payment_terms_and_conditions');
      const baseDataInvalid = (!this.bridgeTerms && termsFieldExists) ||
                               !this.address1 ||
                               !this.city ||
                               !this.state ||
                               !(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(this.zip);
      const achDataInvalid = !this.achAccountName ||
                             !this.achToken ||
                             !this.achAccountType;

      const ccDataInvalid = !this.ccToken ||
                            !this.ccExp ||
                            !this.cvv;

      if (this.paymentType == null && this.creditsCoverTotalCost() && ((termsFieldExists && this.bridgeTerms) || !termsFieldExists)){
        return false;
      }else if (this.paymentType == 'credit') {
        return baseDataInvalid || ccDataInvalid;
      }else if(this.paymentType == 'ach'){
        return baseDataInvalid || achDataInvalid;
      }else{
        return true;
      }
    },
    submit(e) {
      if (this.creditsCoverTotalCost()) this.setNewPaymentMethod('accrued_credits');

      const form = $(this.$el);
      form.submit();
    },
    handleTokenizingResponse: function(event) {
      // Next line doesn't allow us to stub this message out in the user tests
      // if (event.origin !== "https://fts.cardconnect.com") return;
      try {
          var payload = JSON.parse(event.data);
          if (payload.hasOwnProperty('ccToken')) {
            console.log('Received Token (cc)');
            this.ccToken = payload.ccToken;
            document.getElementById('credit_card_token').value = this.ccToken;
          } else if (payload.hasOwnProperty('achToken')) {
            console.log('Received Token (ach)');
            this.achToken = payload.achToken;
            document.getElementById('ach_token').value = this.achToken;
          }
      } catch (e) {
        return false;
      }
    }
  }
}
